import { createSlice } from '@reduxjs/toolkit';
import conObj from '../../utils/connection-assistant';
import { dispatch } from '../store';

const initialState = {
  groups: [],
};

const confirmSlice = createSlice({
  name: 'lookup',
  initialState,
  reducers: {
    setGroups(state, action) {
      state.groups = action.payload;
    },
  },
});

const { actions, reducer } = confirmSlice;

// Reducer
export default reducer;

// Actions
export const { setGroups } = actions;

// Fetchers

export function fetchMyGroups() {
  return conObj.get('MyGroup').then((response) => {
    dispatch(setGroups(response.data));
  });
}
