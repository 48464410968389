import * as Yup from 'yup';
import { useState, useEffect } from 'react';
// import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Alert, IconButton, InputAdornment, Button, Box, Typography, TextField, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import { QrReader } from 'react-qr-reader';
import useAuth from '../../../hooks/useAuth';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { getResponseError } from '../../../utils/formatObject';
import CustomModal from '../../../components/CustomModal';
import QRCodeReader from '../../../components/QrCodeReader';
import useResponsive from '../../../hooks/useResponsive';
import { isValidUrl } from '../../../utils/formatString';
import { getQrCode } from '../../../utils/constant';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();

  const { translate } = useLocales();

  const [showPassword, setShowPassword] = useState(false);

  const [formError, setFormError] = useState('');

  const [openQrReader, setOpenQrReader] = useState(false);

  const [qrCode, setQrCode] = useState('');

  const isMdUp = useResponsive('up', 'md');

  // useEffect(() => {
  //   loadCaptchaEnginge(4);
  // }, []);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required(translate('x_is_required', { x: translate('username') })),
    password: Yup.string()
      .min(6, translate('min_char_is_x', { x: 6 }))
      .required(translate('x_is_required', { x: translate('password') })),
  });

  const defaultValues = {
    username: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    const storedQrCode = sessionStorage.getItem('groupQrCode');

    if (storedQrCode) {
      setQrCode(storedQrCode);
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      const payload = data;
      if (qrCode) {
        payload.joinGroupQrcode = qrCode;
      }

      await login(payload);
    } catch (error) {
      console.error(error);

      reset();

      setFormError(getResponseError(error));
    }
  };

  const handleCancelJoin = () => {
    sessionStorage.removeItem('groupQrCode');
    setQrCode('');
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!formError && <Alert severity="error">{formError}</Alert>}

        <Box sx={{ display: 'flex', gap: 0.5, justifyContent: qrCode ? 'space-between' : 'end' }}>
          {qrCode ? (
            <>
              <Typography color="primary" sx={{ textAlign: 'center' }}>
                {translate('please_login_to_complete')}{' '}
                <Link href="#" onClick={handleCancelJoin} underline="always">
                  {translate('click_to_cancel')}
                </Link>
              </Typography>
            </>
          ) : (
            <Button
              variant="outlined"
              size="small"
              startIcon={<Iconify icon="gg:qr" />}
              onClick={() => setOpenQrReader(true)}
            >
              {translate('scan_qr')}
            </Button>
          )}
        </Box>

        {/* <RHFSelect name="language" label="language">
          <option value="" />
          <option value="English">English</option>
          <option value="Bahasa Melayu">Bahasa Melayu</option>
        </RHFSelect> */}

        {/* <RHFSelect name="country" label="Country">
          <option value="" />
          <option value="Malaysia">Malaysia</option>
          <option value="Thailand">Thailand</option>
        </RHFSelect> */}

        <RHFTextField name="username" label={translate('username')} />

        <RHFTextField
          name="password"
          label={translate('password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" tabindex={-1}>
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" tabindex="-1">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {/* <Stack direction={'row'} spacing={1} alignItems="center">
          <RHFTextField
            name="captcha"
            label="Captcha Code"
            sx={{
              '& .MuiOutlinedInput-input': {
                color: '#fff !important',
              },
            }}
          />
          <Box
            sx={{
              height: 56,
              border: '1px solid rgb(220, 224, 228)',
              borderRadius: '8px',
              pt: '5px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <LoadCanvasTemplateNoReload />
          </Box>
        </Stack> */}

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {translate('login')}
        </LoadingButton>
      </Stack>

      <CustomModal fullScreen={!isMdUp} open={openQrReader} onClose={() => setOpenQrReader(false)}>
        <QRCodeReader
          onScan={(data) => {
            setQrCode(data);
            setOpenQrReader(false);
          }}
        />
      </CustomModal>
    </FormProvider>
  );
}
