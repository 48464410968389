// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
// sections
import { LoginForm } from '../../sections/auth/login';
import Iconify from '../../components/Iconify';
import useLocales from '../../hooks/useLocales';
import LanguagePopover from '../../layouts/dashboard/header/LanguagePopover';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const BackgroundImage = styled(Box)(() => ({
  backgroundImage: `url(${require('../../assets/guest_bg.png')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'fixed',
  width: '100%',
  height: '100%',
  '&::after': {
    content: '""',
    background: 'linear-gradient(145deg, rgba(0,0,0,1) 0%, rgba(79,66,0,0.5) 100%)',
    zIndex: 1,
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3),
  justifyContent: 'end',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(3, 5),
  },
}));

const ContentStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(5, 3, 12, 3),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { translate } = useLocales();

  return (
    <Page title={translate('login')}>
      <RootStyle>
        <HeaderStyle>
          <LanguagePopover />
        </HeaderStyle>
        <BackgroundImage />

        <Container>
          <ContentStyle>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Iconify icon={'ph:spade-fill'} sx={{ fontSize: '4rem', color: 'primary.main' }} />
              <Typography variant="h4" gutterBottom sx={{ mb: 3, color: '#fff' }}>
                {translate('login')}
              </Typography>
            </Box>

            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
