import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import jwtDecode from 'jwt-decode';
// import axios from '../utils/axios';
import { setSession } from '../utils/jwt';
import conObj from '../utils/connection-assistant';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: !!localStorage.getItem('accessToken'),
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        const params = new URLSearchParams(window.location.search);

        const aff = params.get('aff');

        if (aff) sessionStorage.setItem('groupQrCode', aff);

        // if (accessToken && isValidToken(accessToken)) {
        if (accessToken) {
          setSession(accessToken);

          const user = await getUserInfo();

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          if (aff) window.location.replace(`/auth/register/${aff}`);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const getUserInfo = async (isUpdateContext = false) => {
    const { data } = await conObj.get(`MyProfile`);

    const { defaultcurrency } = jwtDecode(localStorage.getItem('accessToken'));

    const retValue = {
      ...data,
      defaultcurrency: defaultcurrency || 'USD',
      displayName: data.username,
    };

    if (isUpdateContext) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: true,
          user: retValue,
        },
      });
    }

    return retValue;
  };

  const login = async (payload) => {
    const response = await conObj.post(`Login`, payload);

    const { jwt } = response.data;

    setSession(jwt);

    const user = await getUserInfo();

    sessionStorage.removeItem('groupQrCode');

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const register = async (payload) => {
    const response = await conObj.post(`Register`, payload);

    const { jwt } = response.data;

    setSession(jwt);

    const user = await getUserInfo();

    sessionStorage.removeItem('groupQrCode');

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        getUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
